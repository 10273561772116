import { cx } from 'cva';

export function Footer() {
  const copyrightYear = new Date().getFullYear();
  return (
    <footer className='flex justify-between'>
      <p className='text-gray-1100'>&copy; {copyrightYear} Clerk</p>
      <ul role='list' className='flex gap-2'>
        {[
          {
            label: 'Support',
            href: 'https://www.clerk.com/support',
          },
          {
            label: 'Privacy',
            href: 'https://www.clerk.com/privacy',
          },
          {
            label: 'Terms',
            href: 'https://www.clerk.com/terms',
          },
        ].map(({ label, href }) => {
          return (
            <li
              key={href}
              className={cx(
                'flex items-center gap-2',
                'after:size-[1.5px] after:rounded-full after:bg-gray-1100',
                'last:after:hidden',
              )}
            >
              <a
                className={cx(
                  'relative rounded-sm text-gray-1000 hover:text-gray-1100',
                  'after:absolute after:-inset-x-1.5 after:-inset-y-0.5 after:rounded-sm after:border-2 after:border-blue-500 after:opacity-0 focus-visible:after:opacity-100',
                )}
                href={href}
              >
                {label}
              </a>
            </li>
          );
        })}
      </ul>
    </footer>
  );
}
