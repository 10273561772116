'use client';

import { mixColor } from 'popmotion';
import { Fragment, useEffect, useRef } from 'react';

type GradientStop = [color: string, position: number];

function createMix(stops: GradientStop[]) {
  const mixers = stops.slice(0, -1).map((stop, stopIndex) => {
    const nextStop = stops[stopIndex + 1];
    return [mixColor(stop[0], nextStop[0]), stop[1], nextStop[1]] as const;
  });

  return (p: number) => {
    for (const [mixer, start, end] of mixers) {
      if (p >= start && p <= end) {
        return mixer((p - start) / (end - start));
      }
    }
  };
}

interface MeteorProps {
  style?: React.CSSProperties;
  meteors: Array<{
    path: string;
    width: number;
    height: number;
    delay?: number;
    repeat?: number;
    onBegin?: () => void | boolean;
    onEnd?: () => void;
  }>;
  id?: string;
  theme?: 'light' | 'dark';
  skip?: number;
}

interface MeteorState {
  path: Path2D;
  meteorLength: number;
  d: number;
  totalLength: number;
  w: number;
  h: number;
  playing: boolean;
}

function getTotalLength(
  segments: number,
  min: number,
  max: number,
  gap: number,
  cap?: number,
) {
  let length = 0;
  for (let i = 0; i < (cap ?? segments); i++) {
    length += min + (max - min) * (i / (segments - 1));
  }
  return length + gap * ((cap ?? segments) - 1);
}

const GRADIENT_STOPS: Record<string, GradientStop[]> = {
  light: [
    ['rgb(93 227 255 / 0)', 0],
    ['rgb(93 227 255)', 0.5],
    ['rgb(108 71 255)', 1],
  ],
  dark: [
    ['rgb(108 71 255 / 0)', 0],
    ['rgb(108 71 255)', 0.5],
    ['rgb(93 227 255)', 1],
  ],
};

export function Meteor({
  meteors,
  style,
  theme = 'light',
  skip = 0,
}: MeteorProps) {
  const gradientStops = GRADIENT_STOPS[theme];

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const svgRefs = useRef<SVGPathElement[]>([]);
  const startRef = useRef<null | number>(null);
  const lastRef = useRef<null | number>(null);
  const elapsedRef = useRef<null | number>(null);
  const stateRef = useRef<MeteorState[]>([]);
  const mix = createMix(gradientStops);
  const glowScale = 2;
  const glowAlpha = 100;
  const segments = 20;
  const minSize = 1.25;
  const maxSize = 1.25;
  const gap = 2.5;

  useEffect(() => {
    const scale = Math.max(1, Math.min(window.devicePixelRatio, 2));

    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    let ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    const w = canvas.offsetWidth * scale;
    const h = canvas.offsetHeight * scale;
    canvas.width = w;
    canvas.height = h;
    ctx.scale(scale, scale);

    const state = stateRef.current;

    if (state.length === 0) {
      for (let i = 0; i < meteors.length; i++) {
        const meteor = meteors[i];

        const meteorLength = getTotalLength(segments, minSize, maxSize, gap);
        const d = -meteorLength * (canvas.width / meteor.width / scale);
        let totalLength = svgRefs.current[i].getTotalLength();
        totalLength = (totalLength * (canvas.width / meteor.width)) / scale;

        const path = new window.Path2D(
          meteor.path
            .replace(
              /H\s*([\d.]+)/g,
              (_, n) => `H${(parseFloat(n) * (w / meteor.width)) / scale}`,
            )
            .replace(
              /V\s*([\d.]+)/g,
              (_, n) => `V${(parseFloat(n) * (h / meteor.height)) / scale}`,
            )
            .replace(
              /(M|L)\s*([\d.]+)\s+([\d.]+)/g,
              (_, c, x, y) =>
                `${c}${(parseFloat(x) * (w / meteor.width)) / scale} ${
                  (parseFloat(y) * (h / meteor.height)) / scale
                }`,
            )
            .replace(
              /C\s*([\d.]+)\s+([\d.]+)\s+([\d.]+)\s+([\d.]+)\s+([\d.]+)\s+([\d.]+)/g,
              (_, x, y, x2, y2, x3, y3) =>
                `C${(parseFloat(x) * (w / meteor.width)) / scale} ${
                  (parseFloat(y) * (h / meteor.height)) / scale
                } ${(parseFloat(x2) * (w / meteor.width)) / scale} ${
                  (parseFloat(y2) * (h / meteor.height)) / scale
                } ${(parseFloat(x3) * (w / meteor.width)) / scale} ${
                  (parseFloat(y3) * (h / meteor.height)) / scale
                }`,
            ),
        );

        state[i] = {
          path,
          meteorLength,
          d,
          totalLength,
          w,
          h,
          playing: false,
        };
      }
    }

    let handle: number;
    const image = new Image();
    image.onload = () => {
      handle = window.requestAnimationFrame(draw);
    };
    image.src =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAMAAAC67D+PAAAAAXNSR0IArs4c6QAAAEhQTFRFAAAA////AP//AAD/AP//gID/AP//AKr/AL//ANX/AKr/ALb/AN//AL//AMb/AMbjAMz/ANH/ALnoANj/AMj/AMjtANX/AMn/G8447gAAABh0Uk5TAAEBAQICAwMEBgYHCAgJCQoLCw0ODhITA3IuUQAAAEdJREFUeNoNycENwzAMBMFdkgmQ/qs1IPHi+Q4qIioMnfXQNdPfD5KJ/op+cgrHeg8rRONSI3vI7t321i7Pngh21blEDCDwB0UUHis/7NTIAAAAAElFTkSuQmCC';

    function draw() {
      if (!canvas || !ctx) {
        return;
      }
      const now = performance.now();
      if (startRef.current === null) {
        startRef.current = now;
      }
      elapsedRef.current = now - startRef.current;

      ctx.clearRect(0, 0, w, h);

      for (let i = 0; i < state.length; i++) {
        if (!svgRefs.current[i]) {
          continue;
        }

        const { delay = 0, repeat, width } = meteors[i];
        const { path } = state[i];
        const meteorLength = getTotalLength(segments, minSize, maxSize, gap);
        let e =
          typeof repeat === 'number'
            ? (elapsedRef.current - delay) % repeat
            : elapsedRef.current - delay;
        e = Math.max(0, e);

        if (e <= 0) {
          continue;
        }

        // state[i].d += (delta * 0.2 * (canvas.width / width)) / scale
        const skipScaled = (skip * (canvas.width / width)) / scale;
        state[i].d =
          skipScaled +
          (-meteorLength + (e * 0.3 * (canvas.width / width)) / scale);

        if (state[i].d > state[i].totalLength) {
          if (state[i].playing) {
            state[i].playing = false;
            meteors[i].onEnd?.();
          }
          continue;
        }
        if (!state[i].playing) {
          state[i].playing = true;
          if (meteors[i].onBegin?.() === false) {
            return;
          }
        }

        ctx.beginPath();

        if (glowAlpha > 0 && glowScale > 0) {
          ctx.globalAlpha = glowAlpha;
          for (let j = 0; j < segments; j++) {
            const todo = getTotalLength(segments, minSize, maxSize, gap, j + 1);
            const offset = todo + state[i].d;
            if (offset < 0 || offset > state[i].totalLength) {
              continue;
            }

            const lineWidth =
              minSize + (j / (segments - 1)) * (maxSize - minSize);

            const point = svgRefs.current[i].getPointAtLength(
              offset + lineWidth / 2,
            );
            const size = lineWidth * glowScale;
            ctx.drawImage(
              image,
              point.x - size / 2,
              point.y - size / 2,
              size,
              size,
            );
          }
          ctx.globalAlpha = 1;
        }

        const pointA = svgRefs.current[i].getPointAtLength(state[i].d);
        const pointB = svgRefs.current[i].getPointAtLength(
          state[i].d + meteorLength,
        );
        const xMin = Math.min(pointA.x, pointB.x);
        const xMax = Math.max(pointA.x, pointB.x);
        const yMin = Math.min(pointA.y, pointB.y);
        const yMax = Math.max(pointA.y, pointB.y);
        ctx.rect(xMin - 16, yMin - 16, xMax - xMin + 32, yMax - yMin + 32);
        const gradient = ctx.createLinearGradient(
          pointA.x,
          pointA.y,
          pointB.x,
          pointB.y,
        );
        for (const stop of gradientStops) {
          gradient.addColorStop(stop[1], stop[0]);
        }
        ctx.globalCompositeOperation = 'source-atop';
        ctx.fillStyle = gradient;
        ctx.fill();
        ctx.globalCompositeOperation = 'source-over';

        for (let j = 0; j < segments; j++) {
          const todo = getTotalLength(segments, minSize, maxSize, gap, j + 1);
          const offset = todo + state[i].d;
          if (offset < 0 || offset > state[i].totalLength) {
            continue;
          }

          const lineWidth =
            minSize + (j / (segments - 1)) * (maxSize - minSize);
          ctx.lineWidth = (lineWidth * (canvas.width / width)) / scale;
          ctx.strokeStyle = mix((j + 1) / segments);
          ctx.setLineDash([0, offset, lineWidth, 999999]);
          ctx.stroke(path);
        }
      }

      lastRef.current = now;

      handle = window.requestAnimationFrame(draw);
    }

    return () => {
      image.onload = null;
      window.cancelAnimationFrame(handle);
      ctx = null;
      state.length = 0;
    };
  }, [
    meteors,
    glowAlpha,
    glowScale,
    minSize,
    maxSize,
    gap,
    segments,
    gradientStops,
    mix,
    skip,
  ]);

  return (
    <>
      <canvas
        ref={canvasRef}
        className='absolute inset-0 h-full w-full'
        style={style}
        aria-hidden
      />
      {meteors.map((meteor, i) => (
        <Fragment key={i}>
          <svg width='0' height='0' aria-hidden>
            <path
              d={meteor.path}
              ref={ref => {
                svgRefs.current[i] = ref!;
              }}
            />
          </svg>
        </Fragment>
      ))}
    </>
  );
}
